import { useLayoutEffect } from "react";
import "../styles/globals.css";
import { EditorProvider, SiteProvider } from "@wazoe/elements";
import AOS from "aos";

function MyApp({ Component, pageProps }) {
  useLayoutEffect(() => {
    AOS.init({ easing: "ease-out-cubic", once: true, offset: 50 });

    setTimeout(() => {
      AOS.refresh();
    }, 500);
  }, []);

  return (
    <div className="overflow-x-clip">
      <EditorProvider
        initMode="production"
        backendApi={pageProps.backendApiUrl}
      >
        <SiteProvider
          initLocale={pageProps?.locale}
          defaultLocale={pageProps.site?.defaultLanguage?.code}
          pages={pageProps?.site?.pages}
          forms={pageProps?.site?.forms}
          menus={pageProps?.site?.menus}
          languages={pageProps?.site?.languages}
          activePage={
            pageProps?.pageData?.path === "/"
              ? `/${pageProps?.locale?.toLowerCase()}`
              : `/${pageProps?.locale?.toLowerCase()}${pageProps?.pageData?.path}`
          }
          textPlaceholders={pageProps?.textPlaceholders}
          repeatableItem={pageProps?.repeatableItem}
          repeatables={pageProps?.repeatables}
        >
          <Component {...pageProps} />
        </SiteProvider>
      </EditorProvider>
    </div>
  );
}

export default MyApp;
